<template>
  <div class="app-container">
    <div>
      <div v-if="!isCountdownActive" class="timer" :style="{ fontSize: fontSize + 'vw', color: fontColor }">{{ currentTime }}</div>
      <div v-else class="countdown">
        <label :style="{ fontSize: '5vw' }">Sisa Waktu:</label><br>
        <div :style="{ fontSize: fontSize + 'vw' }">{{ countdown.minutes < 10 ? '0' + countdown.minutes : countdown.minutes }}:{{ countdown.seconds < 10 ? '0' + countdown.seconds : countdown.seconds }}</div>
      </div>
    </div>
    <!-- <p class="alert" v-if="timeAlert">{{ timeAlert }}</p> -->

    <!-- <div class="speaker-info">
      <h2>{{ currentSpeaker }}</h2>
      <p>{{ currentTopic }}</p>
      <p>{{ sessionStart }} - {{ sessionEnd }}</p>
    </div> -->
    <button class="config-button" @click="showConfig = !showConfig">
      <i class="fas fa-cog"></i>
    </button>

    <div v-if="showConfig" class="modal">
      <div class="modal-content config-panel">
        <span class="close-button" @click="showConfig = false">&times;</span>
        <div>
          <!-- <label for="speakerName">Speaker Name:</label>
          <input v-model="currentSpeaker" id="speakerName" placeholder="Speaker Name">

          <label for="topicTitle">Topic Title:</label>
          <input v-model="currentTopic" id="topicTitle" placeholder="Topic Title"> -->

          <label for="sessionStart">Waktu Mulai:</label>
          <input v-model="sessionStart" id="sessionStart" type="time" placeholder="Waktu Mulai (e.g., 10:00)">

          <label for="sessionEnd">Waktu Selesai:</label>
          <input v-model="sessionEnd" id="sessionEnd" type="time" placeholder="Waktu Selesai (e.g., 10:30)">

          <!-- <label for="alertTime1">First Alert Time (in minutes):</label>
          <input v-model.number="alertTime1" id="alertTime1" type="number" placeholder="First Alert Time (in minutes)">

          <label for="alertTime2">Second Alert Time (in minutes):</label>
          <input v-model.number="alertTime2" id="alertTime2" type="number" placeholder="Second Alert Time (in minutes)"> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSpeaker: 'Speaker Name',
      currentTopic: 'Topic Title',
      sessionTime: '14:00 - 14:50',
      currentTime: new Date().toLocaleTimeString(),
      showConfig: false,
      sessionStart: '14:00',
      sessionEnd: '14:50',
      isCountdownActive: false,
      countdown: {
        minutes: null,
        seconds: null,
      },
      intervalId: null,
      remainingMinutes: null,
      alertTime1: 10, // Default 10 minutes for first alert
      alertTime2: 5,  // Default 5 minutes for second alert
      fontSize: 23, // Default font size
      fontColor: '#000000', // Default font color
    };
  },

  watch: {
    sessionStart(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetTimer();
      }
    },
    sessionEnd(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetTimer();
      }
    },
  },

  mounted() {
    this.startClock();
  },

  methods: {
    resetTimer() {
      this.stopClock();
      this.startClock();
    },
    startClock() {
      this.intervalId = setInterval(() => {
        this.currentTime = new Date().toLocaleTimeString();
        this.updateTimer();
      }, 1000);
    },
    updateTimer() {
      const currentTime = new Date();
      const [endHour, endMinute] = this.sessionEnd.split(':').map(Number);
      const endTime = new Date();
      endTime.setHours(endHour, endMinute, 0, 0);

      const timeDiff = endTime - currentTime; // time difference in milliseconds

      if (timeDiff > 0) {
        this.countdown.minutes = Math.floor(timeDiff / 60000); // convert to minutes
        this.countdown.seconds = Math.floor((timeDiff % 60000) / 1000); // remaining seconds
      } else {
        this.stopClock();
      }

      // Check if total remaining time is less than or equal to 180 seconds (3 minutes)
      if (timeDiff <= 180000) { // 3 minutes * 60 seconds * 1000 milliseconds
        this.isCountdownActive = true;
      } else {
        this.isCountdownActive = false;
      }
    },
    stopClock() {
      clearInterval(this.intervalId);
    }
  },

  computed: {
    timeAlert() {
      if (this.countdown.minutes <= this.alertTime1 && this.countdown.minutes > this.alertTime2) {
        return `${this.alertTime1} minutes left`;
      } else if (this.countdown.minutes <= this.alertTime2) {
        return `${this.alertTime2} minutes left`;
      } else {
        return '';
      }
    }
  },
};
</script>

<style>
.app-container {
  text-align: center;
  font-family: 'Arial', sans-serif;
}
.timer {
  font-size: 3em;
  margin: 10px 0;
}
.speaker-info h2, .speaker-info p {
  margin: 5px 0;
}
.config-panel {
  background-color: #f8f8f8; /* Light grey background */
  border: 1px solid #ddd; /* Slight border for definition */
  padding: 20px; /* Spacing inside the panel */
  margin-top: 20px; /* Space above the panel */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.config-panel input {
  width: 80%; /* Responsive width */
  padding: 10px; /* Comfortable padding inside the input */
  margin-bottom: 10px; /* Space between inputs */
  border: 1px solid #ccc; /* Slight border for inputs */
  border-radius: 4px; /* Rounded corners for inputs */
  font-size: 1em; /* Readable text size */
}
.config-panel label {
  display: block;
  margin-top: 10px;
  color: #333;
  font-weight: bold;
}
.config-button {
  background-color: #4DBA87; /* Theme color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Padding around the text */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1em; /* Matching text size */
}

.config-button:hover {
  background-color: #45a376; /* Slightly darker on hover */
}
.countdown {
  font-size: 2.5em;
  color: red;
}
.alert {
  font-size: 1.2em;
  color: blue;
}
@media (max-width: 600px) {
  .timer, .countdown {
    font-size: 2em;
  }
  .speaker-info h2, .speaker-info p {
    font-size: 1em;
  }
}
.modal {
  position: fixed; /* Positioned relative to the viewport */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.6); /* Black background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%; /* Adjust based on your requirement */
  max-width: 500px; /* Maximum width */
}
.close-button {
  float: right;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
}
</style>
